// Libs
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Carousel, { consts } from 'react-elastic-carousel';

// Components
import { device } from '../device';
import Card from '../Card';

// Images
import arrowRightIcon from 'images/icons/arrow-right.svg';

// Styles
const Container = styled.section`
  padding: ${props =>
    props.whoWeAre ? '0 0 2.875rem 0' : '4.375rem 0 2.875rem'};
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  border-bottom: ${props => (props.isLast ? '0.063rem solid #C6C8CC' : 'none')};

  @media ${device.laptop} {
    padding: ${props => (props.whoWeAre ? '0' : '1.875rem 0')};
    display: block;
    width: ${props => props.whoWeAre && '100%'};
  }

  @media ${device.mobile} {
    padding-bottom: 0;
    width: ${props => props.whoWeAre && '90%'};
  }

  > p {
    margin-bottom: 1.875rem;
    font-size: 1rem;
    line-height: 1.625rem;
    color: #231f20;
    letter-spacing: 0px;
    a {
      color: #45a7df;
      :visited {
        color: #45a7df;
      }
    }
    @media ${device.mobile} {
      font-size: 0.875rem;
    }
  }
`;

const ContainerDescription = styled.div`
  margin-bottom: 1.875rem;
  display: flex;
  grid-column: 2 / -2;

  ${props =>
    props.whoWeAre &&
    `
    margin-left: -1.2rem;
    width: 100%;
  `};

  @media ${device.laptop} {
    ${props => props.whoWeAre && 'margin-left: 0'};
    margin-bottom: ${props => (props.whoWeAre ? '0' : '1.875rem')};
    flex-direction: column;
  }
`;

const Text = styled.span`
  margin-right: ${props => props.isTitle && '1.875rem'};
  width: 570px;

  > :last-child {
    margin-bottom: ${props => props.isBottom && '0'};
  }

  > p {
    margin-bottom: ${props => props.isBottom && '1.875rem'};
    width: ${props => (props.isShort ? '17.5rem' : '34.375rem')};
    font-size: ${props => props.isTitle && '1.5rem'};
    line-height: 26px;

    @media ${device.laptop} {
      margin-bottom: 20px;
      width: 100%;
      line-height: 22px;
    }
    @media ${device.mobile} {
      width: ${props => (props.isShort ? '13.4375rem' : '100%')};
      font-size: ${props => (props.isTitle ? '1.125rem' : '0.875rem')};
    }
  }

  @media ${device.laptop} {
    width: 100%;
    font-size: 0.875rem;
  }
`;

const ContainerMarkdown = styled.div`
  display: flex;
  grid-column: 8 / -2;
  @media ${device.tablet} {
    overflow-x: scroll;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  > p {
    margin-right: 1.875rem;
    width: 35.625rem;
    display: inline-table;

    > img {
      margin-bottom: 0.625rem;
      height: 23.75rem;
    }
    > em {
      color: #8f9194;
      font-size: 0.75rem;
      font-style: normal;
    }
    @media ${device.mobile} {
      margin-right: 0.625rem;
      width: 20.938rem;

      > img {
        height: 13.938rem;
      }

      em {
        font-size: 0.75rem;
      }
    }
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    li {
      @media ${device.laptop} {
        width: ${props =>
          props.whoWeAre
            ? '335px !important'
            : props.tabletAdjustmentCard
            ? 'auto'
            : '570px'};
        margin-right: ${props =>
          props.tabletAdjustmentCard ? '0.625rem' : '1.875rem'};
        :last-child {
          margin-right: 20px;
        }
        img {
          width: ${props => (props.tabletAdjustmentCard ? 'auto' : '100%')};
          height: ${props => (props.tabletAdjustmentCard ? 'auto' : '380px')};

          @media ${device.mobile} {
            height: 23px;
            width: 23px;
          }
        }
        em {
          color: #8f9194;
          font-size: 0.75rem;
          font-style: normal;
          @media ${device.mobile} {
            display: flex;
            width: 271px;
          }
        }
      }
      @media ${device.mobile} {
        width: 16.938rem;
        margin-right: 0.625rem;
      }
    }
    @media ${device.laptop} {
      margin-right: ${props => (props.tabletAdjustmentCard ? '0' : '1.875rem')};
    }
    @media ${device.mobile} {
      margin-right: 0.625rem;
    }
  }

  @media ${device.laptop} {
    padding-bottom: 0;
    width: ${props => (props.scrolled ? '97vw' : 'calc(100% + 3rem)')};
  }

  .rec-arrow {
    display: none;
  }

  .rec-pagination {
    position: absolute;
    @media ${device.mobile} {
      display: none;
    }
  }

  .rec-slider-container {
    @media ${device.laptop} {
      overflow-x: scroll;
      margin: 0;
      ::-webkit-scrollbar {
        width: 0px;
      }
    }
  }

  .rec-item-wrapper {
    width: auto !important;
    margin-right: 0.625rem;

    p {
      display: flex;
      ${props =>
        props.whoWeAre &&
        `
        flex-direction: column;
        font-size: 3.375rem;
        font-weight: 700;

        img {
          margin-bottom: 2.0625rem;
          width: 32.125rem;
          height: 21.5625rem;

          @media ${device.laptop} {
            width: 23.25rem !important;
            height: 15.125rem !important;
          }

          @media ${device.mobile} {
            width: 20.25rem !important;
            height: 13.125rem !important;
          }
        }

        > strong {
          margin-bottom: 1rem;
        }

        strong, em {
          font-size: 1rem;
          line-height: 26px;
          color: #231F20;

          @media ${device.mobile} {
            width: 80%;
            height: auto;
          }
        }

        em {
          font-style: normal;
          font-weight: 400;

          @media${device.laptop} {
            width: 90%;            
          }

          @media ${device.mobile} {
            width: 73%;
            height: 11vh;
          }
        }
      `}
    }

    em {
      color: #8f9194;
      font-size: 0.75rem;
      font-style: normal;
    }
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      li {
        width: 570px;
        margin: 0 1.875rem 0 0;
        :last-child {
          margin-right: 0;
        }
        img {
          width: 100%;
        }
        @media ${device.mobile} {
          width: 335px;
          margin-right: 0.625rem;
        }
      }
    }
  }
`;

const ContainerFooter = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-template-columns: repeat(12, 70px);
  column-gap: 30px;

  @media ${device.laptop} {
    display: block;
  }
`;

const ContainerLinks = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 7 / -1;
  :first-child {
    grid-column-end: 10;
  }
  :last-child {
    grid-column-start: 10;
  }

  > a {
    display: flex;
    width: fit-content;
    align-items: center;
    color: #45a7df;
    font-size: 0.875rem;
    line-height: 2.0625rem;

    :visited {
      color: #45a7df;
    }
    > img {
      width: 1.5rem;
    }
  }
`;

const ContainerItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const BallSlider = styled.div`
  display: flex;
  transform: ${props =>
    props.whoWeAre ? 'translate(0, 0)' : 'translate(-560px, 433px)'};
  position: ${props => (props.whoWeAre ? 'relative' : 'absolute')};
  transform: ${props =>
    props.whoWeAre ? 'translate(0,0)' : 'translate(-560px, 433px)'};
  width: ${props => (props.whoWeAre ? '100%' : 'auto')};
  position: ${props => (props.whoWeAre ? 'relative' : 'absolute')};
  margin-top: ${props => (props.whoWeAre ? '45px' : 'auto')};
  margin-left: ${props => (props.whoWeAre ? '22px' : 'auto')};

  @media ${device.laptop} {
    display: block;
    transform: ${props =>
      props.whoWeAre ? 'translate(0,0)' : 'translate(-560px, 433px)'};
    width: ${props => (props.whoWeAre ? '100%' : 'auto')};
    position: ${props => (props.whoWeAre ? 'relative' : 'absolute')};
  }
`;

const Balls = styled.button`
  margin-right: 8px;
  width: 8px;
  height: 8px;
  border-radius: 1.875rem;
  background-color: ${({ active }) => (active ? '#C6C8CC' : '#FFF')};
  border: 1px solid #c6c8cc;
  cursor: pointer;
  outline: none;
  :active {
    background: #c6c8cc;
    border: unset;
  }
`;

const CharitySociety = ({
  markdown,
  fragments,
  tabletAdjustmentCard,
  isHome,
  whoWeAre,
  infoCard,
}) => {
  const { singletexts } = fragments?.length > 0 && fragments[0];

  const [scroll, setScroll] = useState(false);

  const carousel = useRef(null);

  const infoCarddata = [infoCard];
  const newData = infoCarddata[0].split('y)');

  const [isDesktop, setDesktop] = useState(undefined);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  });

  function renderCarousel() {
    return (
      <Carousel
        ref={carousel}
        itemsToShow={1}
        itemPosition={consts.START}
        renderPagination={({ pages, activePage, onClick }) => {
          return (
            <BallSlider whoWeAre={whoWeAre}>
              {pages.map((page, index) => {
                const isActivePage = activePage === page;
                return (
                  <>
                    <Balls
                      key={index}
                      onClick={() => onClick(page)}
                      active={isActivePage}
                    />
                  </>
                );
              })}
            </BallSlider>
          );
        }}
      ></Carousel>
    );
  }

  return (
    <Container whoWeAre={whoWeAre}>
      {singletexts?.length > 0 && (
        <ContainerDescription whoWeAre={whoWeAre}>
          <Text isShort isTitle>
            <p>{singletexts[0]}</p>
          </Text>
          <Text isBottom>
            <p>{singletexts[1]}</p>
            <p>{singletexts[2]}</p>
            <p>{singletexts[3]}</p>
          </Text>
        </ContainerDescription>
      )}
      <ContainerMarkdown
        onScroll={() => !scroll && setScroll(true)}
        scrolled={scroll}
        whoWeAre={whoWeAre}
        tabletAdjustmentCard={tabletAdjustmentCard}
      >
        {!isHome ? (
          renderCarousel()
        ) : isDesktop ? (
          <ContainerItem>
            {newData.map(
              (item, index) =>
                item !== '' && <Card key={index} children={item} />
            )}
          </ContainerItem>
        ) : (
          <ul>
            {newData.map(
              (item, index) =>
                item !== '' && (
                  <li key={index}>
                    <Card key={item} children={item} />
                  </li>
                )
            )}
          </ul>
        )}
      </ContainerMarkdown>
      {isHome && singletexts?.length > 0 && (
        <ContainerFooter>
          <ContainerLinks>
            <a href={singletexts[4]} target="_self" rel="noopener noreferrer">
              Saiba mais sobre nossa história
              <img src={arrowRightIcon} alt="Saiba mais sobre nossa história" />
            </a>
          </ContainerLinks>
          <ContainerLinks>
            <a href={singletexts[5]} target="_self">
              Trabalhe conosco
              <img src={arrowRightIcon} alt="Trabalhe conosco" />
            </a>
          </ContainerLinks>
        </ContainerFooter>
      )}
    </Container>
  );
};

export default CharitySociety;
