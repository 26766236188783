// Libs
import React from 'react';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import { device } from '../device';

// Style
const Container = styled.div`
  display: flex;
  width: 100%;
  height: ${props =>
    props.height
      ? props.height
      : props.layoutAdjustmentWhoWeAre
      ? '45.5625rem'
      : '35.625rem'};
  font-family: 'Inter', sans-serif;
  position: relative;

  @media ${device.laptop} {
    height: 32rem;
    margin: 0 -1.875rem;
    background-image: ${props =>
      props.history
        ? 'linear-gradient(to right, #1D52a3, #45a7df)'
        : 'linear-gradient(to right, #92559b, #57539f)'};
  }

  @media ${device.tablet} {
    flex-direction: column-reverse;
    height: 50rem;
    margin: 0 -20px;
    padding: 0 1.25rem 0 1.25rem;
  }

  @media ${device.mobile} {
    width: ${props => (props.history ? '100vw' : '100%')};
    height: 40.5rem;
    margin: 0 1.25rem;
    padding: 0.5rem;
    margin-left: ${props => props.history && '60px'};
  }

  span {
    position: absolute;
    bottom: ${props =>
      props.layoutAdjustmentWhoWeAre
        ? '4.375rem'
        : props.history
        ? '2.125rem'
        : '3.125rem'};
    left: ${props =>
      props.layoutAdjustmentWhoWeAre ? '1.0625rem' : '37.5rem'};

    @media ${device.laptop} {
      bottom: 3.875rem;
      left: 31rem;
    }

    @media ${device.tablet} {
      bottom: 17.875rem;
      left: ${props =>
        props.history && props.layoutAdjustmentWhoWeAre
          ? '9.5rem'
          : props.history
          ? '6rem'
          : '1.8rem'};
      display: flex;
      width: 100%;
    }

    @media (min-width: 411px) and (max-width: 648px) {
      bottom: 19rem;
      left: ${props =>
        props.history && props.layoutAdjustmentWhoWeAre
          ? '0.9375rem'
          : props.history
          ? '1.5rem'
          : '1rem'};
    }
    @media (min-width: 375px) and (max-width: 410px) {
      left: ${props =>
        props.history && props.layoutAdjustmentWhoWeAre
          ? '0'
          : props.history && '1rem'};
    }

    @media ${device.mobile375} {
      bottom: 18.5rem;
      left: ${props =>
        props.history && props.layoutAdjustmentWhoWeAre ? '2rem' : '1rem'};
    }

    @media ${device.mobile320} {
      bottom: ${props => (props.history ? '25.5rem' : '19.5rem')};
      height: ${props => props.history && '18.9375rem'};
      left: ${props =>
        props.history && props.layoutAdjustmentWhoWeAre && '1.125rem'};
    }

    .Image-Teaching {
      width: ${props => `${props.imageWidth}px`};
      height: ${props => `${props.imageHeight}px`};

      @media ${device.laptop} {
        width: ${props =>
          props.imageWidthlapTop ? `${props.imageWidthlapTop}px` : '30rem'};
        height: ${props =>
          props.history ? `${props.imageHeight}px` : '30rem'};
      }
      @media (min-width: 769px) and (max-width: 1024px) {
        left: ${props => props.positionLeftLaptop && props.positionLeftLaptop};
        top: ${props => props.positionToptLaptop && props.positionToptLaptop};
      }

      @media ${device.tablet} {
        width: ${props => (props.history ? `${props.imageWidth}px` : '45rem')};
        height: ${props =>
          props.history ? `${props.imageHeight}px` : '34.375rem'};
      }

      @media ${device.mobile} {
        margin-bottom: 2rem;
        width: 23.5rem;
        height: 20.9375rem;
      }

      @media ${device.mobile375} {
        width: 21.5rem;
      }

      @media ${device.mobile320} {
        width: 18rem;
      }

      @media ${device.mobile280} {
        width: 15.5rem;
      }
    }
  }

  ${({ isCompromissoComSaude }) =>
    isCompromissoComSaude &&
    css`
      flex-direction: row-reverse;
      align-items: center;
      gap: 1.875rem;

      > div {
        width: 35.625rem;
        margin: 0;
        top: 0;
        right: 0;
        position: relative;
        gap: 3rem;
        padding: 0;

        h2 {
          margin: 0;
        }

        p {
          width: 100%;
        }

        p:last-child {
          margin: 0;

          a {
            width: 8.438rem;
            color: #45a7df;
          }

          a:visited {
            color: #45a7df;
          }
        }
      }

      span {
        display: flex;
        align-items: center;
        left: 0;
      }

      @media ${device.laptop} {
        background: linear-gradient(to right, #1d52a3, #45a7df);
      }

      @media ${device.tablet} {
        margin: auto 1rem;
        height: 50rem !important;
        padding: 0 1.25rem 1.25rem 1.25rem;

        > div {
          p:last-child {
            justify-content: center;

            a {
              width: 100%;
            }
          }
        }

        span {
          justify-content: center;
        }
      }

      @media ${device.mobile} {
        margin: auto 7rem;
        height: 27.563rem !important;
        padding: 0 1.25rem 1.25rem 1.25rem;

        > div {
          width: 20.938rem;
          gap: 1.25rem;
        }

        span {
          justify-content: center;
          margin-top: -2rem;
          margin-bottom: -2rem;

          > div > div {
            width: 20.938rem !important;
            height: 100% !important;
          }
        }
      }

      @media ${device.mobile320} {
        margin: 0;
      }
    `}
`;

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4.375rem 0 3.125rem 0;

  @media (min-width: 1025px) {
    position: absolute;
    right: ${props => props.history && '1.875rem'};
    top: ${props => props.history && '10rem'};
  }
  ${props =>
    props.history &&
    `
      display: flex;
      align-items: center;
      justify-content: center;

      h2{
        font-weight: 400;
        font-size: 1.5rem;
      }
  `}

  @media ${device.laptop} {
    padding-left: 1.25rem;
  }

  @media ${device.tablet} {
    margin: 0 0 0 0.4rem;
    padding: 0;
    width: 100%;
  }

  @media ${device.mobile} {
    margin: 0;
    width: 100%;
    padding: 0 0.75rem;
  }

  h2 {
    width: ${props => (props.history ? '100%' : '13rem')};
    line-height: 1.875rem;
    margin-bottom: 3.125rem;

    @media ${device.tablet} {
      margin: 1.875rem 0;
      width: 100%;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      display: ${props => props.history && 'flex'};
      position: ${props => props.history && 'absolute'};
      bottom: ${props => props.history && '12.25rem'};
      left: ${props => props.history && '17.875rem'};
    }

    em {
      > a {
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        color: #fff;

        @media ${device.mobile} {
          font-size: 1.125rem;
        }
      }
    }
  }

  p {
    width: ${props => (props.history ? '32.375rem' : '29.375rem')};

    @media ${device.tablet} {
      width: ${props => (props.history ? '100%' : '60%')};
    }

    @media ${device.mobile} {
      width: 100%;
    }

    em {
      > a {
        font-size: 1rem;
        font-style: normal;
        color: #fff;
        line-height: 1.625rem;

        @media (min-width: 768px) and (max-width: 1023px) {
          display: ${props => props.history && 'flex'};
          width: ${props => props.history && '43.625rem'};
          margin-left: ${props => props.history && '1.25rem'};
          margin-right: ${props => props.history && '1.25rem'};
        }

        @media ${device.mobile} {
          font-size: 0.875rem;
        }
      }
    }
  }

  p:last-child {
    margin-top: ${props => (props.history ? '3rem' : '10.5rem')};

    @media ${device.laptop} {
      margin-top: 7.125rem;
    }

    @media ${device.tablet} {
      display: flex;
      justify-content: flex-start;
      height: auto;
      width: 100%;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: ${props =>
        props.history && props.layoutAdjustmentWhoWeAre && '2.5rem'};
    }

    > a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 17.25rem;
      padding: 0.875rem 1.25rem;
      font-size: 0.875rem;
      color: ${props => (props.history ? '#1D52a3' : '#92559b')};
      background: #fff;
      border-radius: 1.875rem;
      cursor: pointer;

      @media ${device.tablet} {
        width: 61%;
        padding: 1.25rem 3.0625rem 1.25rem 3.125rem;
        font-size: 0.875rem;
      }

      @media ${device.mobile} {
        width: 100%;
      }

      @media ${device.mobile375} {
        padding: 1.25rem 3.0625rem 1.25rem 2.125rem;
      }

      @media ${device.mobile320} {
        padding: 1.25rem 2.0625rem 1.25rem 1.125rem;
      }
    }
  }
`;

function LinkRenderer({ href, children }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

const TeachingAndResearch = ({
  markdown,
  imageWidth,
  imageHeight,
  asset,
  history,
  layoutAdjustmentWhoWeAre,
  imageWidthlapTop,
  positionLeftLaptop,
  positionToptLaptop,
  isCompromissoComSaude,
  height,
}) => {
  return (
    <Container
      imageWidth={(asset && imageWidth) || asset.width}
      imageWidthlapTop={imageWidthlapTop}
      imageHeight={(asset && imageHeight) || asset.height}
      positionLeftLaptop={positionLeftLaptop}
      positionToptLaptop={positionToptLaptop}
      history={history}
      layoutAdjustmentWhoWeAre={layoutAdjustmentWhoWeAre}
      isCompromissoComSaude={isCompromissoComSaude}
      height={height}
    >
      <BoxContent history={history}>
        <ReactMarkdown children={markdown} renderers={{ link: LinkRenderer }} />
      </BoxContent>
      {asset && (
        <span>
          <img
            src={asset.url}
            className="Image-Teaching"
            alt="Teaching and Research"
            loading="lazy"
          />
        </span>
      )}
    </Container>
  );
};

export default TeachingAndResearch;
