// Libs
import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import flatten from 'lodash.flatten';
import { graphql } from 'gatsby';

// Components
import { CarouselBanner } from 'components/CmsComponents/CarouselBanner';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { device } from 'components/device';
import PublicationHighlight from 'components/Publications/Highlight';
import { BoxContent } from 'components/footer/style';
import { IslimeComponent } from 'components/doubleIslime/index';
import TeachingAndResearch from 'components/home/teachingAndResearch';
import Services from 'components/units/unitPages/unit/services';
import UnitContent from 'components/units/home/unitContent';
import CharitySociety from 'components/home/charitySociety';
import WhatPatientsSayAboutUs from 'components/home/whatPatientsSayAboutUs';
import OurUnits from 'components/OurUnits';
import ListPosts from 'components/blog/listPosts';
import Components from 'components/CmsComponents/';
import SimpleCarousel from 'components/simpleCarousel';
import Qa from 'components/Qa';

// Context
import { HeaderContext } from 'context/header';

// Image
import doubleIslime from 'images/blobs/double_Islime.svg';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    display: block;
  }
`;

const GridContainer = styled.div`
  padding: ${props => !props.isDesktop && '20px'};
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};

  @media ${device.laptop} {
    padding: ${props => !props.isDesktop && '0 20px'};
  }
`;

const Part = styled.div`
  position: relative;
  margin-bottom: ${props => props.Bottom};
  grid-column: ${props => props.gridColumn};
  margin-top: ${props => props.marginTopSpacement && '2.75rem'};
  padding-bottom: ${props => props.paddingBottom};
  border-bottom: ${props =>
    props.isNotBorder ? 'unset' : '1px solid #c6c8cc'};

  @media ${device.laptop} {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    margin: ${props => props.teaching && '0 -20px'};
  }

  @media ${device.mobile} {
    margin-top: ${props => props.marginTopSpacement && '2.5rem'};
    padding-bottom: ${props => props.paddingBottomMobile};
  }

  ${props =>
    props.padding &&
    css`
      padding: 4.375rem 0;

      @media ${device.laptop} {
        padding: 1.875rem 0;
      }
    `}
`;

const GridTeachingAndResearch = styled.div`
  height: 35.625rem;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  margin-top: 3.3rem;
  column-gap: 30px;
  background-image: linear-gradient(to right, #92559b, #57539f);

  @media ${device.laptop} {
    margin: 4rem -20px 0;
    height: 32rem;
    background: none;
  }

  @media ${device.tablet} {
    margin: 4rem -20px 0;
    height: 50rem;
  }

  @media ${device.mobile} {
    margin-top: 3.5rem;
    width: 100%;
    height: auto;
    display: flex;
    justify-self: center;
  }
`;

const GridCharitySociety = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  -webkit-column-gap: 30px;
  column-gap: 30px;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const GridWhoPatientsSayAboutUs = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  -webkit-column-gap: 30px;
  column-gap: 30px;

  background-color: #f4f5f7;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    margin: 0 -20px;
  }

  @media ${device.tablet} {
    margin: 0 -20px;
    margin-top: 3rem;
  }

  @media ${device.mobile} {
    display: flex;
    justify-self: center;
    height: auto;
  }

  @media ${device.mobile320} {
    padding-left: 0;
    margin: 0 -40px;
  }
`;

const HighlightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: ${props => (props.bgColor ? props.bgColor : '#f4f5f7')};
  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}
  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}

  @media ${device.laptop} {
    ${props =>
      props.marginTopLaptop &&
      css`
        margin-top: ${props.marginTopLaptop};
      `}

    ${props =>
      props.marginBottomLaptop &&
      css`
        margin-bottom: ${props.marginBottomLaptop};
      `}
  }

  @media ${device.mobile} {
    ${props =>
      props.marginTopMobile &&
      css`
        margin-top: ${props.marginTopMobile};
      `}

    ${props =>
      props.marginBottomMobile &&
      css`
        margin-bottom: ${props.marginBottomMobile};
      `}
  }
`;

const HighlightPart = styled.div`
  width: 100%;
  max-width: 1170px;
  padding: ${props => (props.padding ? props.padding : '4.375rem 0 3.125rem')};

  @media ${device.laptop} {
    padding: ${props =>
      props.paddingLaptop ? props.paddingLaptop : '3.125rem 1.25rem 1.875rem'};
  }

  @media ${device.mobile} {
    padding: ${props =>
      props.paddingMobile ? props.paddingMobile : '1.875rem 1.25rem'};
  }
`;

const StyledLayout = styled(Layout)`
  ${BoxContent} {
    margin-top: 4.375rem;
  }
`;

const PartSyled = styled(Part)`
  padding-bottom: 3rem;

  @media ${device.tablet} {
    padding-bottom: 0;
  }
`;

const getMarkdown = (
  page,
  graphUnits,
  fragmentId,
  isMarkdown = false,
  assets = false,
  assetsMultiple = false,
  isSingleText = false
) => {
  const fragments = graphUnits?.[0].fragments || page?.[0].fragments;
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else if (assetsMultiple) {
    return findFragment.assetpickerMultiple;
  } else if (isSingleText) {
    const { singletexts } = flatten(findFragment.localizations)[0];
    return singletexts[0];
  } else {
    if (findFragment && findFragment.localizations) {
      return flatten(findFragment.localizations);
    }
  }
};

const RenderPage = (
  page,
  graphUnits,
  isDesktop,
  isBannerInfoShowing,
  locationsUnits,
  setLocationsUnits
) => {
  const carouselBannerContent = page[0]?.carouselBanner;
  const components = page[0]?.components ?? [];

  return (
    <>
      {carouselBannerContent && (
        <CarouselBanner content={carouselBannerContent} />
      )}
      <GridContainer isDesktop={isDesktop} isHome>
        <PartSyled gridColumn={'1 / -1'} isNotBorder>
          <SimpleCarousel
            markdown={getMarkdown(
              page,
              undefined,
              'ckwxkc5nk00qv0b761kokqlfx',
              true
            )}
            containerBackground={getMarkdown(
              page,
              undefined,
              'ckwxkc5nk00qv0b761kokqlfx',
              false,
              false,
              false,
              true
            )}
            containerHeight="100%"
          />
        </PartSyled>
        <Part
          gridColumn={'2 / -2'}
          paddingBottom="1.875rem"
          paddingBottomMobile="0"
        >
          {Components(components[2])}
        </Part>
        <Part gridColumn={'2 / -2'} marginTopSpacement>
          <>
            <OurUnits
              locationsUnits={locationsUnits}
              setLocationsUnits={setLocationsUnits}
              isHome
              isDesktop={isDesktop}
            />
            {locationsUnits === 'São Paulo, SP' ? (
              <UnitContent
                markdown={getMarkdown(
                  undefined,
                  graphUnits,
                  'ckhq9x53c15a90a24q065278g',
                  true
                )}
                fragments={getMarkdown(
                  undefined,
                  graphUnits,
                  'ckhq9x53c15a90a24q065278g'
                )}
                isHome
              />
            ) : (
              <UnitContent
                markdown={getMarkdown(
                  undefined,
                  graphUnits,
                  'ckhqcdze8175o0e72yk97fhbj',
                  true
                )}
                fragments={getMarkdown(
                  undefined,
                  graphUnits,
                  'ckhqcdze8175o0e72yk97fhbj'
                )}
                isHome
              />
            )}
          </>
        </Part>
        {page?.[0]?.posts?.length > 0 && (
          <Part gridColumn={'2 / -2'} padding>
            <ListPosts
              title="Blog do Sírio-Libanês"
              postsToShow={3}
              posts={page?.[0]?.posts}
              headerLink={`/${page[0]?.customURLBlog ?? 'blog/'}`}
            />
          </Part>
        )}
        <Part gridColumn={'2 / -2'} isNotBorder>
          {locationsUnits === 'São Paulo, SP' && components.length >= 1
            ? Components(components[0])
            : Components(components[1])}
        </Part>
        <GridTeachingAndResearch>
          <Part gridColumn={'2 / -2'} teaching isNotBorder>
            <TeachingAndResearch
              markdown={getMarkdown(
                page,
                undefined,
                'ckkfg9qz48r6k0a72rrhc0g5x',
                true
              )}
              fragments={getMarkdown(
                page,
                undefined,
                'ckkfg9qz48r6k0a72rrhc0g5x'
              )}
              asset={getMarkdown(
                page,
                undefined,
                'ckkfg9qz48r6k0a72rrhc0g5x',
                false,
                true
              )}
            />
          </Part>
        </GridTeachingAndResearch>
        <GridCharitySociety>
          <Part gridColumn={'1 / -1'} isNotBorder>
            <CharitySociety
              markdown={getMarkdown(
                page,
                undefined,
                'ckr0wkkywyjo20c2681hf5gqd',
                true
              )}
              fragments={getMarkdown(
                page,
                undefined,
                'ckr0wkkywyjo20c2681hf5gqd'
              )}
              isShowing
              isHome
              tabletAdjustmentCard
              infoCard={getMarkdown(
                page,
                undefined,
                'ckr0uvo2gxw5j0b84fi4wvyhn',
                true
              )}
            />
            <IslimeComponent isImg={{ doubleIslime }} homePage />
          </Part>
        </GridCharitySociety>
        <GridWhoPatientsSayAboutUs>
          <Part gridColumn={'1 / -1'} isNotBorder>
            <WhatPatientsSayAboutUs
              markdown={getMarkdown(
                page,
                undefined,
                'ckkffwws88qiq0a763lokaqy3',
                true
              )}
              fragments={getMarkdown(
                page,
                undefined,
                'ckkffwws88qiq0a763lokaqy3',
                false
              )}
            />
          </Part>
        </GridWhoPatientsSayAboutUs>
        {page?.[0]?.qa && (
          <Part gridColumn={'2 / -2'} isNotBorder>
            <Qa
              title={'Perguntas Frequentes'}
              data={page?.[0]?.qa}
              showFaqLink="/faq/coronavirus/"
            />
          </Part>
        )}
      </GridContainer>
      <HighlightContainer
        bgColor="linear-gradient(135deg, #1D52A3 0%, #45A7DF 100%)"
        marginBottom="0rem"
        marginBottomLaptop="0rem"
      >
        <HighlightPart padding="4.375rem 0" paddingLaptop="1.875rem">
          <PublicationHighlight />
        </HighlightPart>
      </HighlightContainer>
    </>
  );
};

const IndexPage = ({
  data: {
    gcms: {
      site: { pages },
    },
  },
  pageContext,
}) => {
  const page = pages;
  const graphUnits = pages;
  const [isDesktop, setDesktop] = useState(false);
  const [isBannerInfoShowing, setIsBannerInfoShowing] = useState(true);
  const [locationsUnits, setLocationsUnits] = useState('São Paulo, SP');
  const [load, setLoad] = useState(false);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
    mainMarginTopLaptop,
    mainMarginTopMobile,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (!load) {
      setLoad(true);
    }
  }, [mainMarginTopLaptop, mainMarginTopMobile]);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('8.075rem');
      setMainMarginTopMobile('5.875rem');
    } else {
      setMainMarginTopLaptop('4rem');
      setMainMarginTopMobile('4rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
      if (
        window.location.href === 'https://www.hospitalsiriolibanes.org.br/home/'
      ) {
        window.location.replace('http://hospitalsiriolibanes.org.br/home/');
      }
    }

    // Incluir versao do Package.json no console
    console.log(
      `%c Sharingan: ${require('../../package.json').version}`,
      'color: black; background-color: white; padding: 5px 10px;'
    );
  }, []);

  // useEffect(() => {
  //   let increaseAccess = 0;

  //   if (typeof window !== 'undefined') {
  //     if (localStorage.getItem('userAccess')) {
  //       increaseAccess = parseInt(localStorage.getItem('userAccess')) + 1;

  //       localStorage.setItem('userAccess', increaseAccess);

  //       if (increaseAccess >= 5) {
  //         setIsBannerInfoShowing(false);
  //       }
  //     } else {
  //       localStorage.setItem('userAccess', 1);
  //     }

  //     window.addEventListener('resize', updateMedia);
  //     return () => window.removeEventListener('resize', updateMedia);
  //   }
  // }, []);

  const getImage = page[0]?.featuredMarketing?.images[0];
  useEffect(() => {
    setLocationsUnits(localStorage.getItem('unit') || 'São Paulo, SP');
  }, []);

  if (!load) {
    return <></>;
  }

  return (
    <StyledLayout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      <Container>
        {RenderPage(
          page,
          graphUnits,
          isDesktop,
          isBannerInfoShowing,
          locationsUnits,
          setLocationsUnits
        )}
      </Container>
    </StyledLayout>
  );
};

export const Home = graphql`
  query {
    gcms {
      site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
        pages(where: { id: "ckkesgqco7eqq0a7206jrey0x" }) {
          featuredMarketing(locales: [pt_BR, en]) {
            id
            type
            breadcrumb
            activeBreaknews
            images {
              handle
              width
              height
              url
            }
            breaknews {
              id
              name
              type
              singletexts
              markdown
            }
            backgroundColor {
              hex
            }
            images {
              handle
              width
              height
              url
            }
            title
            fragments {
              id
              name
              type
              markdown
            }
          }
          carouselBanner(locales: [pt_BR, en]) {
            id
            name
            activeBreaknews
            breaknews {
              id
              name
              type
              singletexts
              markdown
            }
            bannersData {
              id
              buttonLink
              buttonText
              backgroundColor {
                hex
              }
              bannerImage {
                url
              }
              bannerImageMobile {
                url
              }
              subtitle
              title
              imageLink
            }
          }
          fragments {
            id
            name
            localizations {
              singletexts
              markdown
            }
            assetpicker {
              handle
              width
              height
              url
            }
            assetpickerMultiple {
              handle
              width
              height
            }
          }
          qa(locales: pt_BR) {
            titulo
            pergunta
            autor
            doctors(first: 1000, locales: [pt_BR, en]) {
              id
              id_api
              active
              assignment
              name
            }
          }
          seo {
            metaTitle
            metaDescription
            noIndex
            localizations {
              metaTitle
              metaDescription
              noIndex
            }
          }
          customURLBlog
          posts {
            id
            slugPost
            title
            topic
            author
            doctors(first: 1000, locales: [pt_BR, en]) {
              id
              id_api
              active
              assignment
              name
            }
            assetpicker {
              handle
              height
              width
              url
            }
            categories {
              tag
            }
          }
          components(locales: [pt_BR, en]) {
            __typename
            ... on GraphCMS_OurSpecialties {
              id
              name
              title
              customFragment {
                id
                name
                type
                markdown
                datafragment
                singletexts
                localizations {
                  markdown
                }
              }
            }
            ... on GraphCMS_DoctorAndSpecialtyContent {
              id
              name
              type
              title
              subtitle
              estado
              images {
                handle
                width
                height
                url
              }
              specialties {
                id
                name
                slug
                customUrl
                unity
              }
              fragments {
                id
                name
                type
                markdown
              }
            }
          }
        }
      }
      pages(where: { id: "ckhq8zt8w14pk0e72cdf9z3op" }) {
        fragments {
          id
          name
          markdown
          localizations {
            markdown
            singletexts
          }
        }
      }
    }
  }
`;

export default IndexPage;
