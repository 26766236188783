// Libs
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

// Images
import closeIcon from 'images/icons/close-menu.svg';
import arrowDown from 'images/icons/arrow-anchor.svg';
import iconCheck from 'images/icons/checkmark-circle.svg';
import arrowRight from 'images/icons/arrow-right.svg';

// Components
import { device } from 'components/device';
import { navigate } from 'gatsby-link';

// Context
import { HeaderContext } from '../context/header';

// Utils
import { isMobile } from 'components/utils/verifyMobile.js';

// Styles
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Content = styled.span`
  font-size: 1.5rem;

  > span {
    margin-left: 5px;
    display: inline-flex;
    align-items: center;
    color: #45a7df;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
    @media ${device.laptop} {
      margin-bottom: 1rem;
      margin-left: 0;
      width: 200px;
      font-size: 1.125rem;
      line-height: 22px;
    }
  }

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    line-height: 2.2rem;
  }

  @media ${device.mobile} {
    width: 177px;
    font-size: 1.125rem;
    line-height: 1.125rem;
  }
`;

const ModalLocations = styled.div`
  position: absolute;
  padding: 1.5625rem 1.25rem;
  margin-top: 0.625rem;
  margin-left: 14.375rem;
  width: 15.625rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  z-index: 5;
  border: 1px solid #c6c8cc80;
  border-radius: 0.5rem;

  > span {
    margin-left: 2.125rem;
    /* margin-bottom: 1.375rem; */
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;

    :nth-child(3) {
      margin-bottom: 0;
      @media ${device.laptop} {
        margin-bottom: 1.375rem;
      }
    }
  }

  @media ${device.laptop} {
    position: fixed;
    margin-left: 0;
    margin-top: 6rem;
    width: 100%;
    min-height: 87.5vh;

    justify-content: space-between;
    border-radius: 0;
  }
`;

const ModalContent = styled.div`
  span {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.875rem;
    margin-bottom: 1.375rem;

    :nth-child(1) {
      margin-bottom: 0;
    }

    @media ${device.mobile} {
      font-size: 1rem;
    }

    :last-child {
      margin-bottom: 0;
      @media ${device.laptop} {
        /* margin-bottom: 1.375rem; */
      }
    }
    > img {
      width: 1.5rem;

      @media ${device.mobile} {
        align-self: flex-start;
      }
    }
  }
`;

const Title = styled.p`
  display: none;
  width: fit-content;

  @media ${device.laptop} {
    margin-bottom: 1.375rem;
    display: flex;
    color: #8f9194;
    font-size: 1rem;
  }
`;

const BoxButtons = styled.div`
  display: none;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    span {
      padding: 1.25rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.875rem;
      cursor: pointer;

      :first-child {
        color: #fff;
        border-radius: 30px;
        background: #45a7df;
        transition: 0.6s background;
        :hover {
          background: #1d52a3;
        }
      }
    }
  }
`;

const ContainerModal = styled.div`
  position: absolute;

  @media ${device.laptop} {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: -webkit-fill-available;
    overflow: hidden;
  }
`;

const HeaderModal = styled.div`
  display: none;

  @media ${device.laptop} {
    padding: 0 20px;
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    height: 97px;
    z-index: 5;
    background: #fff;
    border-bottom: 0.063rem solid #c6c8cc;

    p {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
    }
    img {
      cursor: pointer;
    }
  }
`;

const Seta = styled.img`
  transform: ${props => (!props.isOpenModal ? 'unset' : 'rotate(180deg)')};
`;

const ButtonUnits = styled.button`
  display: flex;
  align-items: center;
  color: #45a7df;
  background: none;
  border: none;
  outline: none;

  :hover {
    text-decoration: underline;
  }
`;

const OurUnits = props => {
  const [modal, setModal] = useState(false);
  const units = ['São Paulo, SP', 'Brasília, DF'];
  const { setHideHeader } = useContext(HeaderContext);

  const modalItem = item => (
    <span
      key={item}
      onClick={() => {
        props.setLocationsUnits(item);
        localStorage.setItem('unit', item);
      }}
    >
      {props.locationsUnits === item && (
        <img src={iconCheck} alt="Selecionado" />
      )}
      <span
        style={{
          marginLeft: `${
            props.locationsUnits !== item ? '2.1rem' : '0.625rem'
          }`,
        }}
        onClick={
          typeof window !== 'undefined' &&
          window.innerWidth > '1024' &&
          (() => setModal(!modal))
        }
      >
        {item}
      </span>
    </span>
  );

  const renderUnitsModal = () => {
    // return props.isHome
    //   ? units.flatMap(item => item !== 'Itaim' && modalItem(item))
    //   : units.map(item => modalItem(item));
    return units.map(item => modalItem(item));
  };

  useEffect(() => {
    if (modal && isMobile) {
      setHideHeader(true);
    }

    if (!modal) {
      setHideHeader(false);
    }
  }, [modal]);

  return (
    <Container specialties={props.specialties}>
      <Content>
        Nossas unidades em
        <span onClick={() => setModal(!modal)}>
          {props.locationsUnits}
          <Seta isOpenModal={!modal} src={arrowDown} alt="Nossas unidades" />
        </span>
        {modal && (
          <ContainerModal>
            <HeaderModal>
              <img
                src={closeIcon}
                onClick={() => setModal(!modal)}
                alt="Fechar"
              />
              <p>Selecione a cidade</p>
            </HeaderModal>
            <ModalLocations isLocation={props.locationsUnits}>
              <ModalContent isHome>
                <Title>Cidades</Title>
                {renderUnitsModal()}
              </ModalContent>
              <BoxButtons>
                <span onClick={() => setModal(!modal)}>Continuar</span>
                <span onClick={() => setModal(!modal)}>Cancelar</span>
              </BoxButtons>
            </ModalLocations>
          </ContainerModal>
        )}
      </Content>
      {props.isHome && props.isDesktop && (
        <ButtonUnits onClick={() => navigate('/unidades/')}>
          <p>Todas as unidades</p>
          <img src={arrowRight} alt="arrow-right" />
        </ButtonUnits>
      )}
    </Container>
  );
};

export default OurUnits;
