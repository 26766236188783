import React from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

const SimpleCarousel = props => {
  const data = [props.markdown];
  const cutData = data[0].split('(end)');

  function LinkRenderer({ href, children }) {
    const handleClick = () => {
      if (href === '/faq/paciente') {
        localStorage.setItem('page', 'portal-do-paciente');
      }
    };

    if (href.includes('http')) {
      return (
        <a href={href} onClick={handleClick} target="_blank">
          {children}
        </a>
      );
    }
  }

  return (
    <S.Container
      containerWidth={props.containerWidth}
      containerHeight={props.containerHeight}
      containerBackground={props.containerBackground}
    >
      <S.WrapContent>
        {cutData.map(
          item =>
            item !== '' && (
              <S.Content
                key={item}
                children={item}
                /* Other props  */
                contentWidth={props.contentWidth}
                contentH2Width={props.contentH2Width}
                contentH2Margin={props.contentH2Margin}
                contentH2FontSize={props.contentH2FontSize}
                contentPFontSize={props.contentPFontSize}
              />
            )
        )}
      </S.WrapContent>
    </S.Container>
  );
};

export default SimpleCarousel;

SimpleCarousel.propTypes = {
  markdown: PropTypes.string,
  containerWidth: PropTypes.string,
  containerHeight: PropTypes.string,
  containerBackground: PropTypes.string,
  contentWidth: PropTypes.string,
  contentH2Width: PropTypes.string,
  contentH2Margin: PropTypes.string,
  contentH2FontSize: PropTypes.string,
  contentPFontSize: PropTypes.string,
};
