import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { device } from '../device';
import { Link } from 'gatsby';

export const ContainerFooter = styled.footer`
  grid-area: FT;
  width: 100%;
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: center;
  background: #fff;

  @media ${device.laptop} {
    width: auto;
  }

  @media ${device.mobile} {
    min-height: max-content;
    height: max-content;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${device.laptop} {
    padding: 0 1.25rem;
  }
`;

export const BoxContent = styled.div`
  padding-top: 3.75rem;
  padding-bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 0.063rem solid #c6c8cc;

  @media ${device.laptop} {
    margin-top: 0;
    padding-top: 1.9375rem;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0;
  }
`;

export const BoxLogoHSL = styled.figure`
  margin-right: 1.25rem;

  @media ${device.laptop} {
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2rem;
    margin-right: 0;
  }
`;

export const LogoHSL = styled.img`
  width: 10.75rem;
  height: 2.5rem;
  object-fit: contain;

  @media ${device.mobile} {
    width: 9.5rem;
    height: 2.1875rem;
  }
`;

export const BoxMedia = styled.div`
  width: 100%;
  display: flex;
  gap: 1.875rem;
  justify-content: end;
  -webkit-justify-content: flex-end;

  @media ${device.laptop} {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  @media ${device.mobile} {
    gap: 1.25rem;
    height: auto;
  }
`;

export const SpacingBox = styled.div`
  padding-bottom: 3rem;
  padding-right: 1.875rem;
  width: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.laptop} {
    padding-right: ${props => props.width && '7%'};
    padding-bottom: 0;
  }
  @media ${device.tablet} {
    padding-right: ${props => props.width && '0'};
  }

  @media ${device.mobile} {
    width: 100%;
    height: auto;
    padding-right: 0;
  }
`;

export const ContentInforFooter = styled(ReactMarkdown)`
  display: flex;
  flex-direction: column;
  max-width: 16.875rem;
  gap: 1.25rem;

  p > strong {
    display: block;
    font-style: normal;
  }

  p > a {
    display: block;
    color: #45a7df;
    font-style: normal;
  }

  p > a > em {
    font-style: normal;
  }

  p > em {
    display: block;
    font-style: normal;
  }

  &.centralHelpSpace {
    p:last-of-type {
      a:nth-child(2) {
        margin: 1.25rem 0;
      }
    }
  }

  &.centralInformationSpace {
    p:nth-child(2) {
      > a {
        color: #8f9194;
      }
    }

    p:nth-child(3) {
      > a {
        color: #8f9194;
      }
    }

    @media ${device.mobile} {
      line-height: 1.25rem;
    }
  }

  @media ${device.mobile} {
    p > strong {
      font-size: 0.875rem;
    }

    p > a {
      font-size: 0.875rem;
      margin-bottom: 0.625rem;
    }

    p > em {
      font-size: 0.875rem;
      margin-bottom: 0.625rem;
    }
  }
`;

export const BadgesContent = styled.div`
  width: 100%;
  height: 30%;
  border-bottom: solid 1px #c6c8cc;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @media ${device.laptop} {
    margin-bottom: 2rem;
    padding: 1rem 0 2rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  @media ${device.mobile} {
    margin-top: 1.5rem;
    padding-top: 0;
    height: auto;
  }
`;

export const TextBadge = styled.p`
  width: 100%;
  padding: 1.15rem 0;
  font-size: 0.875rem;
  color: #c6c8cc;

  @media ${device.laptop} {
    padding-top: 0;
    padding-bottom: 1.5rem;
    width: 100%;
    font-size: 0.875rem;
  }
`;

export const BoxBadges = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3rem;

  @media ${device.laptop} {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 0rem;
  }

  @media ${device.mobile} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.6rem 0.85rem;
    align-items: center;
    justify-items: center;
  }
`;

export const Badges = styled.img`
  object-fit: contain;

  width: 3.75rem;
  height: auto;
  max-height: 80px;

  :nth-child(3) {
    width: 3.2rem;
  }

  :nth-child(6) {
    width: 4.375rem;
  }

  :nth-child(7) {
    width: 4.375rem;
  }
  :nth-child(10) {
    width: 6.75rem;
    grid-column: 2 / span 1;
    justify-self: start;
  }
`;

export const BoxMenuFooter = styled.div`
  margin-bottom: 0;
  width: 100%;
  height: 9.5625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.laptop} {
    margin-bottom: 1rem;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const MenuFooter = styled.ul`
  padding: 0;
  margin: 0;
  margin-top: 2.375rem;
  margin-bottom: 6.25rem;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.laptop} {
    margin-top: 0;
    margin-bottom: 1rem;
    width: 100%;
    min-height: max-content;
    height: max-content;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const MenuItemInternal = styled(Link)`
  margin-right: ${props => (props.black ? '1.1rem' : '0.3rem')};
  margin-bottom: 0;
  font-size: 0.75rem;
  color: ${props => (props.black ? '#231F20' : '#8F9194')};
  list-style: none;
  cursor: ${props => !props.black && 'pointer'};

  &.cmp-revoke-consent {
    display: inherit;
    position: unset;
    padding: unset;
  }

  @media ${device.laptop} {
    padding-bottom: ${props => (props.black ? '1.875rem' : '1.25rem')};
    margin: 0;
    font-size: 0.875rem;
  }

  &:hover {
    ${props => !props.black && 'text-decoration: underline'};
  }
`;

export const MenuItemExternal = styled.a`
  margin-right: ${props => (props.black ? '1.1rem' : '0.3rem')};
  margin-bottom: 0;
  font-size: 0.75rem;
  color: ${props => (props.black ? '#231F20' : '#8F9194')};
  list-style: none;
  cursor: ${props => !props.black && 'pointer'};

  &.cmp-revoke-consent {
    display: inherit;
    position: unset;
    padding: unset;
  }

  @media ${device.laptop} {
    padding-bottom: ${props => (props.black ? '1.875rem' : '1.25rem')};
    margin: 0;
    font-size: 0.875rem;
  }

  &:hover {
    ${props => !props.black && 'text-decoration: underline'};
  }
`;

export const MenuItemButton = styled.button`
  border: 0;
  background: transparent;
  margin-right: ${props => (props.black ? '1.1rem' : '0.3rem')};
  margin-bottom: 0;
  font-size: 0.75rem;
  color: ${props => (props.black ? '#231F20' : '#8F9194')};
  list-style: none;
  cursor: ${props => !props.black && 'pointer'};

  &.cmp-revoke-consent {
    display: inherit;
    position: unset;
    padding: unset;
  }

  @media ${device.laptop} {
    padding-bottom: ${props => (props.black ? '1.875rem' : '1.25rem')};
    margin: 0;
    font-size: 0.875rem;
  }

  &:hover {
    ${props => !props.black && 'text-decoration: underline'};
  }
`;

export const MenuItemNoLink = styled.p`
  margin-right: ${props => (props.black ? '1.1rem' : '0.3rem')};
  margin-bottom: 0;
  font-size: 0.75rem;
  color: ${props => (props.black ? '#231F20' : '#8F9194')};
  list-style: none;
  cursor: ${props => !props.black && 'pointer'};

  @media ${device.laptop} {
    padding-bottom: ${props => (props.black ? '1.875rem' : '1.25rem')};
    margin: 0;
    font-size: 0.875rem;
  }

  &:hover {
    ${props => !props.black && 'text-decoration: underline'};
  }
`;

export const BoxSocial = styled.div`
  margin-bottom: 6.25rem;
  margin-top: 2.375rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.laptop} {
    margin: 0;
    padding-top: 1rem;
    height: 35%;
  }

  @media ${device.mobile} {
    padding: 0;
    height: max-content;
  }
`;

export const SocialLink = styled.a`
  margin-right: 0.625rem;
  width: 1.875rem;
  height: 1.875rem;

  &:last-child {
    margin-right: 0;
  }

  @media ${device.laptop} {
    margin-right: 1.25rem;
    width: 1.875rem;
    height: 1.875rem;
  }
`;

export const ImgSocial = styled.img`
  width: 100%;
  height: 100%;
`;
