import React from 'react';
import { ContainerIslime, ImgIslime } from './style';

export function IslimeComponent({ isImg, homePage }) {
  return (
    <ContainerIslime homePage={homePage}>
      {isImg && isImg !== '' && (
        <ImgIslime
          className="img-IslimeComponent"
          src={isImg.doubleIslime}
          alt="img-IslimeComponent"
        />
      )}
    </ContainerIslime>
  );
}
