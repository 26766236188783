import styled from 'styled-components';
import { device } from '../device';

export const ContainerIslime = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  margin-left: ${props => (props.homePage ? '4rem' : '9.1875rem')};

  .img-IslimeComponent {
    max-width: 622px;
    width: 100%;
    z-index: -1;
    position: relative;
    margin-top: -20rem;
    top: 11.6875rem;
    margin-bottom: -170px;
  }

  @media ${device.desktopLarge} {
    margin-left: ${props => props.homePage && '20rem'};
  }

  @media ${device.laptop} {
    margin-left: 5.1875rem;

    .img-IslimeComponent {
      margin-bottom: ${props => props.homePage && '-180px'};
      margin-top: ${props => props.homePage && '-220px'};
      margin-left: ${props => props.homePage && '-9rem'};
    }
  }

  @media (min-width: 768px) and (max-width: 1000px) {
    .img-IslimeComponent {
      width: ${props => (props.homePage ? '52%' : '50%')};
      top: 6.125rem;
      margin-bottom: ${props => (props.homePage ? '-160px' : '-100px')};
      margin-top: ${props => (props.homePage ? '-130px' : '-100px')};
      margin-left: ${props => props.homePage && '-6rem'};
    }
  }

  @media ${device.mobile500} {
    justify-content: center;
    margin-left: auto;
    padding-top: ${props => (props.homePage ? '35px' : '120px')};
    .img-IslimeComponent {
      padding-bottom: 120px;
      margin-bottom: ${props => (props.homePage ? '-200px' : '-150px')};
      margin-left: ${props => props.homePage && '-3rem'};
    }
  }

  @media ${device.mobile375} {
    .img-IslimeComponent {
      margin-left: ${props => props.homePage && '-3rem'};
      margin-top: ${props => props.homePage && '-250px'};
      padding-bottom: ${props => props.homePage && '-200px'};
      margin-bottom: ${props => props.homePage && '-200px'};
    }
  }

  @media ${device.mobile320} {
    .img-IslimeComponent {
      margin-left: ${props => props.homePage && '-2rem'};
      margin-top: ${props => props.homePage && '-220px'};
      padding-bottom: ${props => props.homePage && '-240px'};
      margin-bottom: ${props => props.homePage && '-90px'};
    }
  }
`;
export const ImgIslime = styled.img`
  display: flex;
`;
