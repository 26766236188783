import styled from 'styled-components';
import { Link } from 'gatsby';
import { device } from 'components/device';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
  }
`;

export const HeaderTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: normal;
  color: #ffffff;
  margin: 0;

  @media ${device.laptop} {
    width: 35%;
    font-size: 1.125rem;
  }
`;

export const HeaderLink = styled(Link)`
  color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 1rem;

  @media ${device.mobile320} {
    font-size: 0.875rem;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 1.875rem;
  flex-wrap: wrap;

  @media ${device.laptop} {
    flex-direction: column;
    gap: 3.125rem;
  }
`;

export const Publication = styled.a`
  width: 100%;
  max-width: 23.125rem;

  @media ${device.laptop} {
    display: flex;
    gap: 1.25rem;
  }
`;

export const PublicationThumbnail = styled.div`
  width: 23.125rem;
  height: 15.375rem;
  background-color: #e0e0e0;
  margin-bottom: 1.875rem;

  img {
    width: 100%;
    max-width: 23.125rem;
    height: 100%;
    max-height: 15.375rem;
    object-fit: cover;
    object-position: center 75%;
  }

  @media ${device.laptop} {
    width: 7.5rem;
    height: 7.5rem;
    margin-bottom: 0;

    img {
      width: 7.5rem;
      height: 7.5rem;
      object-position: center;
    }
  }
`;

export const PublicationInfo = styled.small`
  font-size: 0.875rem;
  line-height: 1rem;
  color: #ffffff;
  font-weight: normal;
  margin-bottom: 0;

  @media ${device.laptop} {
    font-size: 0.75rem;
    line-height: 1rem;
  }
`;

export const PublicationTitle = styled.h5`
  font-size: 1.25rem;
  line-height: 1.625rem;
  color: #ffffff;
  font-weight: 600;

  margin-bottom: 1.875rem;

  @media ${device.laptop} {
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 0;
  }

  @media ${device.mobile320} {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
`;

export const PublicationDescription = styled.p`
  font-size: 1rem;
  line-height: 1.625rem;
  color: #ffffff;
  font-weight: normal;

  @media ${device.laptop} {
    font-size: 0.875rem;
    line-height: 1.625rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  @media ${device.mobile320} {
    font-size: 0.875rem;
    line-height: 1.625rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;
