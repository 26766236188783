// Libs
import React from 'react';
import styled from 'styled-components';

import ReactMarkdown from 'react-markdown';
import { device } from '../device';

export const Card = styled.div`
  height: 11.25rem;
  width: 16.875rem;
  border-radius: 8px;
  border: 1px solid #c6c8cc;
  padding: 1.25rem;
  margin-bottom: 1.875rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    a {
      color: black;
      img {
        height: 23px;
        line-height: 1.813rem;
        @media ${device.mobile} {
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 1.375rem;
        }
      }
    }
  }
`;

const Cards = props => {
  function LinkRenderer({ href, children }) {
    const handleClick = () => {
      if (href === '/faq/doacoes') {
        localStorage.setItem('page', 'doacoes');
      }
    };
    return (
      <a
        href={href}
        onClick={handleClick}
        target={props.isFaq ? '_self' : '_blank'}
        rel="noreferrer noopener"
      >
        {children}
      </a>
    );
  }

  return (
    <Card className={props.className}>
      <ReactMarkdown
        renderers={{ link: LinkRenderer }}
        key={props.children}
        children={props.children}
      ></ReactMarkdown>
    </Card>
  );
};

export default Cards;
