import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import {
  Container,
  Header,
  HeaderTitle,
  HeaderLink,
  Content,
  Publication,
  PublicationThumbnail,
  PublicationInfo,
  PublicationTitle,
  PublicationDescription,
} from './style';

import ArrowRight from 'images/icons/arrow-right-white.svg';

const getNewerPublications = (
  page,
  excludedFragments = [],
  numberOfPublications = 3
) => {
  const { fragments } = page[0];
  if (!(excludedFragments instanceof Array))
    throw new Error('excludedFragments must be an array');

  const foundFragments = fragments.reduce((acc, item) => {
    const foundInExcludedFragment = excludedFragments.find(
      excludedFragment => excludedFragment === item.id
    );

    if (foundInExcludedFragment) return acc;

    return (acc = [...acc, item.datafragment]);
  }, []);

  if (!foundFragments) return [];

  const listOfPublications = foundFragments.flat(Infinity).filter(e => e);
  const threeNewerPublications = listOfPublications.slice(
    0,
    numberOfPublications
  );

  return threeNewerPublications;
};

const PublicationHighlight = ({ page }) => {
  const publications = getNewerPublications(page, [
    'cku722h5clqxm0c73e279mif1',
    'cku723s7slr930c28ukgeka40',
  ]);

  return (
    <Container>
      <Header>
        <HeaderTitle>Revista Viver</HeaderTitle>
        <HeaderLink to="/revista-viver">
          Ver mais Publicações <img src={ArrowRight} alt="Ver mais" />
        </HeaderLink>
      </Header>
      <Content>
        {publications.map((publication, index) => (
          <Publication
            key={index}
            href={publication.file_link || '#'}
            target="_blank"
            rel="noreferrer"
          >
            <PublicationThumbnail>
              {publication.thumbnail ? (
                <img
                  src={publication.thumbnail || ''}
                  alt={publication.title || ''}
                  loading="lazy"
                />
              ) : (
                <></>
              )}
            </PublicationThumbnail>
            <div>
              <PublicationInfo>
                {publication.edition || ''} - {publication.date || ''}
              </PublicationInfo>
              <PublicationTitle>{publication.title || ''}</PublicationTitle>
              <PublicationDescription>
                {publication.description || ''}
              </PublicationDescription>
            </div>
          </Publication>
        ))}
      </Content>
    </Container>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(
                locales: [pt_BR, en]
                where: { id: "cku6ywsk0jecm0b71ioz5wqp3" }
              ) {
                fragments {
                  id
                  name
                  datafragment
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <PublicationHighlight page={response.gcms.site.pages} {...props} />
        );
      }}
    />
  );
};
