import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

// Components
import { device } from 'components/device';

export const Container = styled.div`
  width: ${({ containerWidth }) => containerWidth || '100vw'};
  height: ${({ containerHeight }) => containerHeight || '100%'};
  background: ${({ containerBackground }) => containerBackground || '#fff'};

  @media ${device.tablet} {
    width: 101vw;
    margin: 0 1.5rem 0 -1.4rem;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    scroll-behavior: smooth;
    padding: 0px 1rem 0 1.6rem;
  }

  @media ${device.mobile} {
  }
`;

export const WrapContent = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 4.5rem 0 4.375rem 0;
  gap: 1.875rem;

  @media ${device.tablet} {
    width: 71.938rem;
    margin-right: 4.5rem;
    padding: 0 0 2rem 0;
  }
`;

export const Content = styled(ReactMarkdown)`
  width: ${({ contentWidth }) => contentWidth || '16.875rem'};
  border-top: 1px solid #000;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.875rem 0 0 0;

  h2 {
    width: ${({ contentH2Width }) => contentH2Width || '100%'};
    margin: ${({ contentH2Margin }) => contentH2Margin || '0'};
    font-size: ${({ contentH2FontSize }) => contentH2FontSize || '1rem'};

    a {
      color: #000;
    }
  }

  p {
    font-size: ${({ contentPFontSize }) => contentPFontSize || '1rem'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #000;
    height: 7rem;

    a:first-child {
      color: #000;
    }

    a:last-child {
      display: flex;
      align-items: center;
      color: #45a7df;
    }
  }

  @media ${device.tablet} {
    padding: 1.875rem 0 0 0;

    h2 {
      font-size: 0.875rem;
    }

    p {
      height: 6.5rem;
      a {
        font-size: 0.875rem;
      }
    }
  }

  @media ${device.mobile} {
  }
`;
