// Libs
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import styled, { css } from 'styled-components';

// Components
import { device } from '../device';

//Images
import iconArrow from 'images/icons/arrow-right.svg';
import grayArrow from 'images/icons/gray_arrow.svg';

const Container = styled.section`
  display: grid;
  padding: 4.75rem 0;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;

  @media ${device.laptop} {
    padding: 2.4rem 0;
  }

  @media ${device.mobile} {
    display: flex;
    justify-content: center;
  }
`;

const Content = styled.section`
  display: flex;
  flex-direction: column;
  grid-column: 2 / -1;

  ${({contentPadding}) => contentPadding && css`
  
      @media ${device.laptop} {
        grid-column: 3  / -1;
      }
      
      @media ${device.tablet} {
        padding: ${({contentPadding}) => contentPadding ? contentPadding : ''};
        grid-column: 1 / -1;
      }
  
  `};


  @media ${device.mobile} {
    width: 100%;
    grid-column: 2 / -1;
    padding: 0;
  }
`;

const Title = styled.section`
  width: 18rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.875rem;
  color: #231f20;
  margin-bottom: ${props =>
    props.withoutAvatarImage ? '4.375rem !important' : 'unset'};

  @media ${device.laptop} {
    font-size: ${props => props.withoutAvatarImage && '1.125rem'};
    line-height: ${props => props.withoutAvatarImage && '1.2rem'};
    margin-bottom: ${props =>
      props.withoutAvatarImage ? '1.875rem !important' : 'unset'};
  }

  @media ${device.tablet} {
    width: ${props => (props.withoutAvatarImage ? '18rem' : '100%')};
  }

  @media ${device.mobile} {
    margin-left: ${props =>
      props.withoutAvatarImage ? '1.25rem !important' : '4.5rem'};
    text-align: ${props => (props.withoutAvatarImage ? 'left' : 'center')};
    width: 16rem;
    font-size: 1.125rem;
    line-height: 1.125rem;
  }

  @media ${device.mobile375} {
    margin-left: 3.7rem;
  }

  @media ${device.mobile320} {
    margin-left: ${props =>
      props.withoutAvatarImage ? '2.25rem !important' : '3rem'};
  }
`;

const MainText = styled.p`
  margin-top: 2rem;

  @media ${device.mobile} {
    display: none;
  }
`;

export const SayAbout = styled.section`
  display: flex;
  position: relative;
  gap: ${props => (props.withoutAvatarImage ? '1.875rem' : 'unset')};

  @media ${device.laptop} {
    flex-direction: ${props => props.withoutAvatarImage && 'column'};
  }

  @media ${device.tablet} {
    margin-left: ${props => (props.withoutAvatarImage ? 'unset' : '6rem')};
  }

  @media ${device.mobile} {
    margin-left: 0;
  }

  p {
    padding-top: ${props => (props.withoutAvatarImage ? 'unset' : '3.125rem')};
    display: flex;
    font-size: 1rem;
    line-height: 1.625rem;

    @media ${device.mobile} {
      flex-direction: column;
    }

    @media ${device.laptop} {
      padding-top: ${props => (props.withoutAvatarImage ? 'unset' : '2rem')};
    }

    :nth-child(3) {
      img {
        margin: 0 1.875rem 1.2rem 1.875rem;

        @media ${device.laptop} {
          margin: 0 1.6rem 1.2rem 1.2rem;
        }
      }

      strong {
        padding-top: ${props =>
          props.withoutAvatarImage ? 'unset' : '3.4375rem'};
      }
    }

    img {
      margin: 0 1.875rem 1.2rem 0;
      width: 10.625rem;
      height: 10.625rem;
      border-radius: 50%;

      @media ${device.laptop} {
        margin: 0 1.3rem 1.2rem 0;
      }

      @media ${device.mobile} {
        :nth-child(1) {
          display: flex;
          align-self: center;
          margin-left: 1rem;
        }
      }

      @media ${device.mobile375} {
        :nth-child(1) {
          margin-left: 1.5rem;
        }
      }

      @media ${device.mobile320} {
        :nth-child(1) {
          margin-left: 1.2rem;
        }
      }
    }

    em {
      width: ${props => (props.withoutAvatarImage ? '100%' : '23.125rem')};
      max-width: ${props => (props.withoutAvatarImage ? '35.625rem' : 'unset')};
      height: ${props => (props.withoutAvatarImage ? '100vh' : 'unset')};
      max-height: ${props => (props.withoutAvatarImage ? '11.25rem' : 'unset')};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-style: normal;
      color: #231f20;

      @media ${device.laptop} {
        width: ${props => (props.withoutAvatarImage ? '94vw' : '17.5rem')};
        max-width: ${props => props.withoutAvatarImage && 'unset'};
        height: ${props => props.withoutAvatarImage && 'unset'};
        max-height: ${props => props.withoutAvatarImage && 'unset'};
        gap: ${props => props.withoutAvatarImage && '20px'};
        font-size: 0.9rem;
      }

      @media ${device.mobile} {
        width: 100%;
        text-align: ${props => (props.withoutAvatarImage ? 'center' : 'unset')};
        justify-content: ${props =>
          props.withoutAvatarImage ? 'unset' : 'center'};
        align-items: ${props =>
          props.withoutAvatarImage ? 'unset' : 'center'};
        justify-self: ${props =>
          props.withoutAvatarImage ? 'unset' : 'center'};
        padding-right: 1.25rem;
        padding-left: 1.25rem;
        font-size: 0.8rem;
      }

      @media ${device.mobile320} {
        padding-right: ${props =>
          props.withoutAvatarImage ? '2.375rem' : '1.6rem'};
        padding-left: ${props =>
          props.withoutAvatarImage ? '2.375rem' : '2.5rem'};
      }
    }

    strong {
      padding-top: 0.5rem;
      font-size: 0.875rem;
      font-weight: 400;
      color: #8f9194;

      @media ${device.mobile} {
        text-align: ${props => (props.withoutAvatarImage ? 'left' : 'unset')};
      }
    }
  }
`;

const Icon = styled.img`
  display: none;

  @media ${device.tablet} {
    display: ${props => (props.withoutAvatarImage ? 'none' : 'flex')};
    position: relative;
    transform: ${props => props.rotation && 'rotate(180deg)'};
    cursor: pointer;
    outline: none;
  }

  @media ${device.mobile} {
    position: absolute;
    margin-top: 6rem;

    :first-child {
      margin-left: 0.6rem;
    }

    :last-child {
      right: 0.8rem;
    }

    @media ${device.mobile320} {
      :first-child {
        margin-left: 1.5rem;
      }

      :last-child {
        margin-right: 1rem;
      }
    }
  }
`;

export default function WhatPatientsSayAboutUs({
  markdown,
  fragments,
  withoutAvatarImage = false,
  mainText,
  contentPadding,
  className = ""
}) {
  const [current, setCurrent] = useState(0);
  const [isDesktop, setDesktop] = useState(undefined);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth < 1024);
    }
  });

  const { singletexts } = fragments && fragments[0];
  const listMarkdown = [markdown];
  const filterByPathname = listMarkdown[0].split('\n\n');
  const length = filterByPathname.length - 1;

  const prevSlide = () => {
    if (current !== 0) {
      setCurrent(current === 0 ? length - 1 : current - 1);
    }
  };

  const nextSlide = () => {
    if (current !== length - 1) {
      setCurrent(current === length - 1 ? 0 : current + 1);
    }
  };

  return (
    <Container withoutAvatarImage={withoutAvatarImage} className={className}>
      <Content withoutAvatarImage={withoutAvatarImage} contentPadding={contentPadding}>
        <Title withoutAvatarImage={withoutAvatarImage}>{mainText ? singletexts[0] : singletexts}</Title>
          {mainText && <MainText>{singletexts[1]}</MainText>}
        <SayAbout withoutAvatarImage={withoutAvatarImage}>
          <Icon
            style={{
              background: 'none',
              width: '1.5rem',
              display: withoutAvatarImage
                ? 'none'
                : isDesktop === false
                ? 'none'
                : 'flex',
            }}
            src={current === 0 ? grayArrow : iconArrow}
            alt="Prev"
            rotation={current !== 0}
            onClick={prevSlide}
            withoutAvatarImage={withoutAvatarImage}
            loading="lazy"
          />
          <ReactMarkdown
            children={
              withoutAvatarImage
                ? markdown || '-'
                : isDesktop
                ? filterByPathname[current]
                : markdown || '-'
            }
            allowDangerousHtml
          />
          <Icon
            style={{
              background: 'none',
              width: '1.5rem',
              display: withoutAvatarImage
                ? 'none'
                : isDesktop === false
                ? 'none'
                : 'flex',
            }}
            src={current === length - 1 ? grayArrow : iconArrow}
            rotation={current === length - 1}
            alt="Next"
            onClick={nextSlide}
            withoutAvatarImage={withoutAvatarImage}
            loading="lazy"
          />
        </SayAbout>
      </Content>
    </Container>
  );
}
